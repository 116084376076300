body, h1, h2, p {
  margin: 0;
  padding: 0;
}

.Section {
  display: flex;
  flex-direction: row;
  margin: 30px 10% 60px;
  flex-wrap: wrap;
  align-items: center;
}

.Section img {
  max-width: 90%;
  max-height: 100%;
  border-radius: 10px;
  object-fit: contain;
}

.TextColumn {
  flex: 1;
  max-width: 70%;
  flex-basis: 70%;
  justify-content: flex-start;
}

.ImageColumn {
  flex: 1;
  max-width: 30%;
  flex-basis: 30%;
  justify-content: flex-start;
}

.TextSection {
  color: rgb(128, 0, 96);
  margin: 0;
  width: 100%;
  align-self: flex-start;
}

.Title {
  margin: 0;
  font-size: 65px;
  font-weight: 600;
  text-align: left;
  font-family: 'Dancing Script';
  color: rgb(255, 98, 208);
}

.Subtitle {
  margin: 0;
  font-size: 40px;
  font-weight: 600;
  text-align: left;
  font-family: 'Dancing Script';
  color: rgb(167, 129, 255);
}

.Body, .MusicLink {
  font-size: 32px;
  font-family: 'Dancing Script';
}

.MeText {
  font-family: 'Helvetica', sans-serif;
  font-weight: 600;
  color:rgb(85, 209, 236);
}

.MusicLink {
  color: #fff;
}

@media screen and (max-width: 1300px) {
  .Section {
    margin-top: 40px;
  }
}

@media screen and (max-width: 960px) {
  .Section {
    flex-direction: column;
    align-items: center;
  }

  .ImageColumn {
    order: 1;
    max-width: 50%;
  }
  .TextColumn {
    order: 1;
    max-width: 100%;
  }
  
  .TextSection {
    margin: 30px 0 0 0;
    text-align: center;
    font-size: 20px;
  }

  .Title {
    text-align: center;
    font-size: 45px;
  }

  .Subtitle {
    text-align: center;
    font-size: 35px;
  }
}

@media screen and (max-width: 500px) {
  .Section {
    margin: 30px 5% 0;
  }

  .Title {
    font-size: 30px;
  }

  .Subtitle {
    font-size: 20px;
  }

  .Body {
    font-size: 16px;
  }
}

@media screen and (max-width: 400px) {
  .Subtitle {
    font-size: 16px;
  }
}