.footer-container {
  background-color: #282828;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;  
  padding: 24px;
  color: #fff;
}

.footer > p {
  font-family: 'Trebuchet MS';
}

.footer-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-text, .footer-text-link   {
  font-size: 20px;
  font-family: 'Trebuchet MS';
  color: #fff;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 40px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

@media screen and (max-width: 768px) {
  .footer-heading {
    font-size: 18px; 
  }

  .footer-text, .footer-text-link {
    font-size: 16px; 
  }

  .social-icon-link {
    font-size: 30px; 
  }
}