* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Bokor';
}
  
body {
  margin: 0;
  padding: 0;
  background-color: pink;
}

.skills{
  color: #fff1cb;
  font-size: 100px;
  background-color: #79b0e4;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  font-family: 'Permanent Marker';
  @media (max-width: 960px) {
    font-size: 25px; 
  }
}

.skills-container {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(#79b0e4, #caddf0);
  color: #fff1cb;
  font-size: 100px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  font-family: 'Permanent Marker';
  margin-bottom: -45px;
  @media only screen and (max-width: 960px) {
    max-width: 100%;
  }
} 

.home-container {
  background-color: #000000; 
  padding: 0px; 
  margin-bottom: -18px;
  .rounded-iframe {
    border-radius: 12px !important; 
  }
}

.experience-container {
  background-color: #4f3831; 
  font-family: 'IM Fell DW Pica';
  margin-bottom: -18px;
}

.about-container {
  background-image: linear-gradient(to top right, rgb(51, 214, 255), pink, pink, rgb(255, 255, 101)); 
  font-family: 'Dancing Script';
  padding: 40px 0 0;    
  margin-bottom: -15px;

}

.music-container{
  background-image: linear-gradient(#a68249, #eccc8c);
  font-family: 'Tenor Sans';
  padding: 40px;   
}

.cookies-container{
  background-image: linear-gradient(to bottom, rgb(16, 16, 63), rgb(18, 18, 68), rgb(94, 62, 7),  rgb(13, 13, 82), rgb(40, 41, 83),  rgb(43, 43, 63) ,rgb(34, 34, 71));
  font-family: 'Tenor Sans';
  padding: 0px;   
  margin-bottom: -18px;
}
