
.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  background-color: #282828;
}

body {
  background-color: #282828; 
  z-index: 9999;
}

.joke-text {
  font-family: 'Space Mono', sans-serif; 
  font-size: 24px; 
  text-align: center;
  margin-top: 10px;
  color: hotpink;
}