.techCards {
  padding: 4rem;
  background: #79b0e4;
  background-image: linear-gradient(#79b0e4, #caddf0);
}

h1 {
  text-align: center;
}

.techCards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
}

.techCards__wrapper {
  position: relative;
  margin: -80px 0 45px;
}

.techCards__items {
  margin-bottom: 24px;
}

.techCards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.techCards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 10px 10px 20px rgba(84, 100, 194, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(19, 172, 19, 0.017));
  filter: drop-shadow(0 6px 20px rgba(211, 11, 11, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.techCards__item__text {
  color: #fcffd6;  
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  outline-width: 1px;
  font-size: 25px;
  line-height: 40px;
  text-align: center;
  font-family: 'Rock Salt';
  @media (max-width: 960px) {
    font-size: 20px; 
  }
}

.techCards__item__pic-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.techCards__label {
  font-family: 'Indie FLower';
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #fff1cb;   
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  @media (max-width: 960px) {
    font-size: 35px; 
  }
}

.techCards__item__img {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 87%;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
  align-items: center;
  justify-content: center;
}

.techCards__item__img:hover {
  transform: scale(1.1);
}

.techCards__item__info {
  padding: 20px 20px 20px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 960px) {
  .techCards__items {
    display: flex;
  }
}

@media only screen and (max-width: 960px) {
  .techCards__item {
    margin-bottom: 2rem;
  }
}
  