.Header{
    font-size: 120px;
    font-family: 'Arial';
    color: #ffffff;
    font-weight: 600;
}

.image-gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

.gallery-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
}

@media screen and (max-width: 960px) {
  .Header{
    font-size: 60px;
  }
}


