body.music-container {
  margin: 0px;
  overflow: hidden;
  background-image: linear-gradient(#a68249, #eccc8c);
  min-height: 100vh;
  position: relative;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;  
}

.Header{
  font-size: 120px;
  font-family: 'Tenor Sans';
  color: #664324;
  font-weight: 600;
}

.Subheader{
  font-size: 45px;
  color: #664324;
  font-family: 'Tenor Sans';
}

body, h1, h2, p {
  margin: 0;
  padding: 0;
}

.music-Section {
  display: flex;
  flex-direction: row;
  margin: 30px 10% 0;
  flex-wrap: wrap;
  align-items: center;
}

.music-Section img {
  max-width: 70%;
  max-height: 100%;
  border-radius: 10px;
  object-fit: contain;
}

.music-TextColumn {
  flex: 1;
  max-width: 70%;
  flex-basis: 70%;
  justify-content: flex-start;
}

.music-ImageColumn {
  flex: 1;
  max-width: 30%;
  flex-basis: 30%;
  justify-content: flex-start;
}

.music-TextSection {
  color: #FFFFFF;
  margin: 0;
  width: 100%;
  align-self: flex-start;
}

.music-Title {
  margin: 0;
  font-size: 65px;
  font-weight: 600;
  text-align: left;
  font-family: 'Tenor Sans';
  color: rgb(255, 98, 208);
}

.music-Subtitle {
  margin: 0;
  font-size: 40px;
  font-weight: 600;
  text-align: left;
  font-family: 'Tenor Sans';
  color: rgb(167, 129, 255);
}

.music-Body {
  font-size: 32px;
  font-family: 'Tenor Sans';
}

@media screen and (max-width: 1300px) {
  .Section {
    margin-top: 40px;
  }
}

@media screen and (max-width: 960px) {
  .Section {
    flex-direction: column;
    align-items: center;
  }

  .ImageColumn {
    order: 1;
    max-width: 50%;
  }
  .TextColumn {
    order: 1;
    max-width: 100%;
  }
  
  .TextSection {
    margin: 30px 0 0 0;
    text-align: center;
    font-size: 20px;
  }

  .Title {
    text-align: center;
    font-size: 45px;
  }

  .Subtitle {
    text-align: center;
    font-size: 35px;
  }
}

@media screen and (max-width: 500px) {
  .Section {
    margin: 30px 5% 0;
  }

  .Title {
    font-size: 30px;
  }

  .Subtitle {
    font-size: 20px;
  }

  .Body {
    font-size: 16px;
  }
}

@media screen and (max-width: 400px) {
  .Subtitle {
    font-size: 16px;
  }
}