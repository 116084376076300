body.contact-form-container {
  margin: 0px;
  overflow: hidden;
  background-image: linear-gradient(#2a0447, #9d80b7);
  min-height: 100vh;
  position: relative;
  z-index: 2;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;  
}

form {
  margin-top: -150px;
  background-color: #623887;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  align-self: center;
  text-align: center;
  z-index: 2;
}

.spotify-embeds{
  position: relative;
  z-index: 2;
  margin-bottom: -20px;
}

h1 {
  font-family: "Sacramento";
  color: #fff;
  font-weight: 550;
  font-size: 40px;  
  text-align: center;
}

h2 {
  font-family: "Sacramento";
  color: #fff;
  font-weight: 550;
  font-size: 50px;  
  text-align: center;
}

h3 {
  font-family: "Sacramento";
  color: #fff;
  font-weight: 550;
  font-size: 40px;  
  text-align: center;
}

input, textarea, .contact-form button {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-family: "PT Sans";
  font-size: medium;
}

.contact-form button {
  background-color: #a67bcb;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;
  height: 55px;
  font-family: "Sacramento";
  font-size: 30px;
  font-weight: 550;  
}

.contact-form button:hover {
  background-color: #8667a5;
}

.stars {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
}

.star {
  position: absolute;
  background: #fff;
  width: 2px;
  height: 2px;
  animation: twinkle 3s infinite;
  box-shadow: 0 0 10px 5px rgb(199, 140, 233);  
}

@keyframes twinkle {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}